import WindowEventListener from '../../utils/WindowEventListener';
import TTI from './TTI';
import ConsoleLog from '../../utils/ConsoleLog';
import AppLogRequester from '../../requesters/AppLogRequester';
import WebLogRequester from '../../requesters/WebLogRequester';
import CommonParameter from '../../parameters/CommonParameter';
import NetworkParameter from '../../parameters/NetworkParameter';
import ExtraParameter from '../../parameters/ExtraParameter';

const SCHEMA_ID = 137;
const SCHEMA_VERSION = 3;
const IMG_TAG_SELECTOR = 'img[data-load-time]';
const IMG_DATA_SELECTOR = 'data-load-time';
const TAG = 'TTILog';

// calculate types constants
const CT_EXCLUDE_REDIRECT = 'excludeRedirect';

export default class TTILog {
  private ttiObjects: object;
  private commonFields: CommonFields;
  private baseOption: OptionFields;
  private serverTime: number;
  private domReady: number;
  private imageLoadingTime: number;
  private latestImageLoadingTime: number;
  private imageCount: number;
  private loadedCount: number;
  private syncRecorded: boolean;
  private asyncLogs: AsyncLogs[];
  private commonParameter: CommonParameter;
  private webLogRequester: WebLogRequester;
  private appLogRequester: AppLogRequester;
  private calcType: string;

  constructor(webLogRequester: WebLogRequester, appLogRequester: AppLogRequester) {
    this.ttiObjects = {};
    this.loadedCount = 0;
    this.asyncLogs = [];
    this.imageLoadingTime = 0;
    this.latestImageLoadingTime = 0;
    this.webLogRequester = webLogRequester;
    this.appLogRequester = appLogRequester;
  }

  /**
   * TTI Initializer
   *
   * @param ttiFields {
   *     pageName {string} - page name
   *     platform {string} - android|ios|web|mweb
   *     platformType {string} - native|browser|...
   *     screenType {string} - device screen type
   *     extra {object} - extra fields need added into the log
   *     async {boolean} - true async mode, false sync mode
   *     calcType {string} - tti calcuation type, 'default' means tti value will include redirect time, 'excludeRedirect' means tti value will exclude redirect time.
   * }
   */
  init(ttiFields: TTIFields): void {
    const {
      pageName,
      platform,
      platformType,
      screenType,
      extra,
      async = false,
      calcType = 'default'
    } = ttiFields;

    this.commonFields = {
      domain: 'tti',
      logCategory: 'system',
      logType: 'performance',
      eventName: 'tti-logger',
      pageName,
      platformType,
      ixid: this.generateUUID(),
    };

    this.baseOption = {
      screenType,
      extra,
      async,
    };

    this.calcType = calcType || 'default';

    this.commonParameter = new CommonParameter().setPlatform(platform);
    this.updatePerformanceValues(async);
  }

  record(key: string, optionFields: OptionFields = {}): void {
    const startTime = window.performance.now();
    if(this.ttiObjects.hasOwnProperty(key)) {
      return ConsoleLog.w(TAG, `Already ${key} is recording the TTI`);
    }
    (optionFields['extra'] || (optionFields['extra'] = {}))['ttiRecordKey'] = key;
    optionFields['async'] = true;
    this.ttiObjects[key] = new TTI(startTime, optionFields);
  }

  stop(key: string): void {
    if(!this.ttiObjects.hasOwnProperty(key)) {
      return ConsoleLog.w(TAG, `${key} must first record`);
    }
    const ttiObject = this.ttiObjects[key];
    const tti = (() => {
      ttiObject.stop(window.performance.now());
      delete this.ttiObjects[key];
      return ttiObject.measureLatency();
    })();
    this.recordAsync(tti, ttiObject.getOptionFields());
  }

  manualRecord(extraFields: Object = {}): void {
    const tti = Math.floor(window.performance.now());
    const optionsFields = Object.assign({}, this.baseOption, {
      async: true,
      extra: Object.assign(extraFields, this.baseOption.extra),
    });
    this.recordAsync(tti, optionsFields);
  }

  logImageLoadTime(element): void {
    if (window.performance.getEntriesByName) {
      const entryKey = element.getAttribute('src').replace(/^(\/\/)/, `${location.protocol}$1`);
      const entries = performance.getEntriesByName(entryKey);
      const entry = entries.length > 0 && entries[0] || null;
      const responseEnd = entry && entry.responseEnd || 0;
      const duration = entry && entry.duration || 0;
      const start = entry && entry.startTime || 0;
      const end = (responseEnd > 0) ? responseEnd : start + duration;
      if(start > 0 && end > 0) {
        element.setAttribute(`${IMG_DATA_SELECTOR}-start`, start);
        element.setAttribute(`${IMG_DATA_SELECTOR}-end`, end);
      }
    }
    element.setAttribute(IMG_DATA_SELECTOR, window.performance.now());
  }

  private recordAsync(tti: number, optionFields: OptionFields): void {
    this.asyncLogs.push({
      tti: tti,
      optionFields: optionFields
    });
    this.domReady && this.submitAsync();
  }

  private submitAsync(): void {
    this.asyncLogs.map(log => this.submit(log.tti, Object.assign({
      serverTime: this.serverTime,
      domReady: this.domReady,
      imageLoadingTime: this.imageLoadingTime
    }, log.optionFields)));
    this.asyncLogs = [];
  };

  private submit(tti: number, optionFields: OptionFields): void {
    const isApp = this.appLogRequester.isApp();
    const requestJSON = this.makeParams(tti, optionFields);
    if (isApp) {
      this.appLogRequester.send(requestJSON)
    } else {
      this.webLogRequester.send(requestJSON);
    }
    ConsoleLog.d(TAG, `#submitSync - tti: ${tti}, serverTime: ${optionFields.serverTime}, domReady: ${optionFields.domReady}, imageLoadingTime: ${optionFields.imageLoadingTime}`);
  }

  private makeParams(tti: number, optionFields: OptionFields): TTIParams {
    const extraData = {...new NetworkParameter().getNetworkParameters(), url: location.href, ...optionFields.extra, calcType: this.calcType};
    const extra = new ExtraParameter().setExtraData(extraData).setSentTime(new Date().toISOString()).getJSON();
    this.commonParameter.setEventTime(new Date().toISOString());
    delete optionFields.extra;

    return {
      ...this.commonParameter.getJSON(),
      ...extra,
      meta: {
        schemaId: SCHEMA_ID,
        schemaVersion: SCHEMA_VERSION,
      },
      data: Object.assign({ tti }, this.commonFields, optionFields)
    };
  }

  private submitSync(): void {
    if(!this.syncRecorded && this.domReady) {
      let tti = this.domReady;
      if(this.imageCount > 0) {
        tti = Math.max(this.domReady, this.latestImageLoadingTime);
        this.baseOption.extra = Object.assign({ 'latestImageLoadingTime': this.latestImageLoadingTime }, this.baseOption.extra);
      }
      this.syncRecorded = true;
      this.submit(tti, Object.assign({ serverTime: this.serverTime, domReady: this.domReady, imageLoadingTime: this.imageLoadingTime }, this.baseOption));
    }
  }

  private getLatestImageLoadTime(): void {
    const elements = document.querySelectorAll(IMG_TAG_SELECTOR);
    const imgCount = this.imageCount = elements.length;
    const startTimes = [];
    const endTimes = [];
    for(let i = 0; i < imgCount; i++) {
      const loadTime = Number(elements[i].getAttribute(IMG_DATA_SELECTOR));
      startTimes.push(elements[i].getAttribute(`${IMG_DATA_SELECTOR}-start`));
      endTimes.push(elements[i].getAttribute(`${IMG_DATA_SELECTOR}-end`));
      this.latestImageLoadingTime = this.getActualTime(Math.max(this.latestImageLoadingTime, Math.floor(loadTime)));
    }
    if(startTimes.length > 0 && endTimes.length > 0) {
      this.imageLoadingTime = Math.max(0, Math.floor(Math.max(...endTimes) - Math.min(...startTimes)));
    }
  }

  private updatePerformanceValues(isAsync: boolean): void {
    WindowEventListener.add('load', () => {
      if (this.supportTimingApi()) {
        this.serverTime = Math.max(0, (window.performance.timing.responseEnd - window.performance.timing.requestStart));
        this.domReady = this.getActualTime(window.performance.timing.domContentLoadedEventEnd - window.performance.timing.navigationStart);
      }
      this.getLatestImageLoadTime();
      !isAsync && this.submitSync();
      // to send async logs sent before the 'window.load' event fired
      this.asyncLogs.length > 0 && this.submitAsync();
    });
  }

  /**
   * Get actual time value that consider the calc type
   */
  private getActualTime(time: number) {
    // minus redirect time
    if (this.calcType === CT_EXCLUDE_REDIRECT && this.supportTimingApi()) {
      time -= (window.performance.timing.fetchStart - window.performance.timing.navigationStart);
    }

    return Math.max(0, time);
  }

  private supportTimingApi() {
    return window.performance && window.performance.timing;
  }

  private generateUUID(): string {
    let uuid = '';

    for(let i = 0; i < 32; i++) {
      const random = Math.random() * 16 | 0;

      if (i === 8 || i === 12 || i === 16 || i === 20) {
        uuid += '-';
      }
      uuid += (i === 12 ? 4 : (i === 16 ? (random & 3 | 8) : random)).toString(16);
    }

    return uuid;
  }
}
