const APIS = Object.freeze({
  V1: {
    SINGLE_SUBMIT: 'weblog/submit',
  },
  V2: {
    SINGLE_SUBMIT: 'api/v2/submit',
  },
});

const DOMAINS = Object.freeze({
  KR: {
    public: {
      production: 'ljc.coupang.com',
      integration: 'ljc-test.coupang.com',
      develop: 'ljc-test.coupang.com',
      internal: 'weblog.coupangdev.com',
    },
    private: {
      // FIXME: New private subdomains are not ready yet
      production: 'weblog-private.coupang.net',
      integration: 'weblog-test-private.coupang.net',
      develop: 'weblog-test-private.coupang.net',
      internal: 'weblog.coupangdev.com'
    },
  },
  TW: {
    public: {
      production: 'ljc.tw.coupang.com',
      integration: 'ljc-test.tw.coupang.com',
      develop: 'ljc-test.tw.coupang.com',
      internal: 'weblog.coupangdev.com',
    },
    private: {
      // FIXME: New private subdomains are not ready yet
      production: 'weblog-private.coupang.net',
      integration: 'weblog-test-private.coupang.net',
      develop: 'weblog-test-private.coupang.net',
      internal: 'weblog.coupangdev.com',
    },
  },
});

export default class EndPointsConfig {
  private readonly appCode: string;
  private readonly host?: string;
  private readonly mode: Mode;
  private readonly market?: string;
  private readonly usePrivateELB: boolean;
  private readonly useV2API: boolean;

  constructor({ appCode, host, market, mode = 'production', usePrivateELB, useV2API }: Config) {
    this.appCode = appCode;
    this.host = host;
    this.market = market;
    this.mode = mode;
    this.usePrivateELB = usePrivateELB;
    this.useV2API = useV2API;

    // FIXME: Should be refactored after TS version up
    if (host && !host.startsWith('https://')) {
      throw new Error('host param should be starts with https://');
    }
  }

  /**
   * Return single submit api endpoint
   *
   * @returns {string} endpoint
   */
  getSingleSubmitEndpoint(): string {
    return this.useV2API
      ? `${this.baseUrl()}/${APIS.V2.SINGLE_SUBMIT}?appCode=${this.appCode}&market=${this.market}`
      : `${this.baseUrl()}/${APIS.V1.SINGLE_SUBMIT}/${this.appCode}`;
  }

  private baseUrl() {
    // Use custom base url when the host config is specified
    if (this.host !== undefined) {
      return this.host;
    }

    const marketInfo = DOMAINS[this.market] || DOMAINS['KR'];

    const domainsFromMode = marketInfo[this.usePrivateELB ? 'private' : 'public'];

    return `https://${domainsFromMode[this.mode] || domainsFromMode.production}`;
  }
}
