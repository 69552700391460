import ConsoleLog from './utils/ConsoleLog';

const TAG = 'AppLogRequester';
/**
 * This class provides the ability to pass log data from the WebView to the App in the Coupang App
 */
export default class AppLogRequester {
  /**
   * Check for Coupang App.
   *
   * @returns {boolean} Returns true if the app is true or false otherwise.
   */
  isApp(): boolean {
    return this.hasAndroidFunction() || this.hasIOSFunction();
  }

  /**
   * Send the log data to Coupang App.
   *
   * @param jsonData JSON object for log data
   */
  send(jsonData: object): void {
    let jsonString = JSON.stringify(jsonData);

    if (this.hasAndroidFunction()) {
      ConsoleLog.d(TAG, `#send - using window.CoupangAppTracking.logEvent function`);
      window.CoupangAppTracking.logEvent(jsonString);
    } else if (this.hasIOSFunction()) {
      ConsoleLog.d(TAG, `#send - using window.webkit.messageHandlers.callBackHandler.postMessage function`);
      window.webkit.messageHandlers.callBackHandler.postMessage(jsonString);
    } else {
      ConsoleLog.w(TAG, '#send - Not found App Function.');
      return;
    }
    ConsoleLog.i(TAG, `#sendToApp - jsonString \n ${jsonString}`);
  }

  /**
   * Android WebView determines whether there is a function for transmitting log data.
   *
   * @returns {boolean} Returns true if window.CoupangAppTracking.logEvent is present, false otherwise.
   */
  private hasAndroidFunction(): boolean {
    return !!(window.CoupangAppTracking &&
              window.CoupangAppTracking.logEvent &&
              typeof window.CoupangAppTracking.logEvent === 'function');
  }

  /**
   * iOS WebView determines whether there is a function for transmitting log data.
   *
   * @returns {boolean} Returns true if window.webkit.messageHandlers.callBackHandler.postMessage is present, false otherwise.
   */
  private hasIOSFunction(): boolean {
    return !!(window.webkit &&
              window.webkit.messageHandlers &&
              window.webkit.messageHandlers.callBackHandler &&
              window.webkit.messageHandlers.callBackHandler.postMessage &&
              typeof window.webkit.messageHandlers.callBackHandler.postMessage === 'function');
  }
}
